.cancel-btn {
    background-color: #e7e7e7 !important;
    color: black !important;
    border: thin solid #ddd !important;
    border-radius: 0 !important;
}
.cancel-btn:hover {
    border-color: #adadad !important;
    background-color: #e7e7e7 !important;
}
.confirm-btn {
    background-color: @lightPrimary !important;
    border-color: @lightPrimary !important;
    border-radius: 0 !important;
}

.confirm-btn:hover {
    background-color: @primary !important;
    border-color: @primary !important;
}
.modal-popup{
    width: 800px !important;
    margin:0 auto;
}
.modal-html-container{
    text-align: left !important;
}

